<template>
  <div class="projectOverview" :class="bgimg">
    <!-- 上下结构设计，底部固定高度，顶部自适应 -->
    <div class="header">
      <!-- 顶部左中右结构，flex布局，中间自适应 -->
      <div class="h-left">
        <div class="heat-box census" v-loading="status.censusStatus">
          <div class="heat-title">
            <span>综合能耗统计</span>
            <!-- <span class="showFull" @click="showFull">
              {{ isProjectOverview == "true" ? "退出全屏" : "展示全屏" }}
            </span> -->
          </div>
          <div class="census-body">
            <div class="census-left">
              <div class="census-left-number">
                <div class="c-l-n-value">
                  {{
                    censusData.value === null
                      ? "-- --"
                      : (censusData.value / 1000).toFixed(1)
                  }}
                </div>
                <div class="c-l-n-name">吨标煤</div>
              </div>
              <div class="census-left-name">
                <div class="census-left-name-value">月综合能耗</div>
                <!--<div class="census-left-name-img"></div> -->
              </div>
            </div>
            <div class="census-variety">
              <div class="census-variety-item">
                <div class="c-variety-item-number bottom">
                  <i class="el-icon-caret-bottom"></i>
                  <!-- {{
                    yoyData.comparePercent === null
                      ? "-- --"
                      : Math.abs(yoyData.comparePercent) + "%"
                  }} -->
                  -- --
                </div>
                <!-- <div class="c-variety-item-time">同比{{ yoyData.time }}</div> -->
                <div class="c-variety-item-time">同比{{ yoyData2 }}</div>
              </div>
              <div class="census-variety-item">
                <div
                  class="c-variety-item-number"
                  :class="qoqData.comparePercent < 0 ? 'bottom' : 'top'"
                >
                  <i
                    :class="
                      qoqData.comparePercent < 0
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-top'
                    "
                  ></i>
                  <!-- {{
                    qoqData.comparePercent === null
                      ? "-- --"
                      : Math.abs(qoqData.comparePercent) + "%"
                  }} -->
                  3.2%
                </div>
                <!-- <div class="c-variety-item-time">环比{{ qoqData.time }}</div> -->
                <div class="c-variety-item-time">环比{{ qoqData2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="heat-box analysis" v-loading="status.analysis">
          <div class="heat-title">单位能耗分析</div>
          <div class="analysis-body">
            <div class="analysis-item">
              <div class="analysis-title">单位面积综合能耗</div>
              <div class="analysis-item-center">
                <div class="analysis-left square"></div>
                <div class="analysis-right">
                  <div class="analysis-right-num">
                    {{
                      this.analysisData.area === null
                        ? "-- --"
                        : this.analysisData.area
                    }}
                  </div>
                  <div>kgce/㎡.a</div>
                </div>
              </div>
            </div>
            <div class="analysis-item">
              <div class="analysis-title">人均综合能耗</div>
              <div class="analysis-item-center">
                <div class="analysis-left user"></div>
                <div class="analysis-right">
                  <div class="analysis-right-num">
                    {{
                      this.analysisData.people === null
                        ? "-- --"
                        : this.analysisData.people
                    }}
                  </div>
                  <div class="analysis-right-val">kgce/人.a</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="heat-box pattern">
          <div class="heat-title">暖通系统</div>
          <div class="pattern-body">
            <div class="pattern-item stop">
              <div class="pattern-left"></div>
              <div class="pattern-name">制冷系统</div>
              <div class="pattern-right">
                <!-- <img
                  v-if="coldData != 1"
                  src="~@/assets/heatingSystemWebp/冷冻投用2倍图.webp"
                  alt=""
                /> -->
                <img src="~@/assets/heatingSystem/停用.png" alt="" />
                <div class="pattern-right-bg"></div>
              </div>
              <!-- <div class="pattern-icon"></div> -->
              <!-- <img
                class="icon"
                v-if="coldData != 1"
                src="~@/assets/heatingSystemWebp/引导按钮.webp"
                alt=""
              /> -->
              <img
                class="icon"
                src="~@/assets/heatingSystem/stop-iocn.png"
                alt=""
              />
              <div class="pattern-val">运行模式：<span>手动</span></div>
            </div>
            <div class="pattern-item start" @click="goHeatingSystem">
              <div class="pattern-left"></div>
              <div class="pattern-name">采暖系统</div>
              <div class="pattern-right">
                <img
                  v-if="hotData === 1"
                  src="~@/assets/heatingSystemWebp/采暖投用2倍图.webp"
                  alt=""
                />
                <img v-else src="~@/assets/heatingSystem/停用2.png" alt="" />
                <div class="pattern-right-bg"></div>
              </div>
              <!-- <div class="pattern-icon"></div> -->
              <img
                class="icon"
                v-if="hotData === 1"
                src="~@/assets/heatingSystemWebp/引导按钮2.webp"
                alt=""
              />
              <img
                class="icon"
                v-else
                src="~@/assets/heatingSystem/start-icon.png"
                alt=""
              />
              <div class="pattern-val">
                运行模式：<span>{{ hotData === 1 ? "寻优" : "手动" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-center">
        <img
          class="center-header"
          src="~@/assets/heatingSystem/center-header.png"
          alt=""
        />
        <div class="center-list" v-loading="status.loadCenterData">
          <div class="center-item">
            <div class="center-val">
              {{ Number(getMonitorRealtimeData).toFixed(1) }}<span>KW</span>
            </div>
            <div class="center-name">实时负荷</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              {{
                censusDataAll.value
                  ? (censusDataAll.value / 1000).toFixed(1)
                  : "--"
              }}<span>tce</span>
            </div>
            <div class="center-name">累计节能(标煤)</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              {{
                totalSavingFeeData
                  ? (totalSavingFeeData / 10000).toFixed(1)
                  : "--"
              }}<span>万元</span>
            </div>
            <div class="center-name">累计节费(万元)</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              {{
                totalReductionData
                  ? (totalReductionData / 10000).toFixed(1)
                  : "--"
              }}<span>吨</span>
            </div>
            <div class="center-name">累计减排(CO₂)</div>
          </div>
        </div>
        <div class="center-foot"></div>
      </div>
      <div class="h-right">
        <div class="heat-box reduce" v-loading="status.reduceData">
          <div class="heat-title">节能总览</div>
          <div class="reduce-body">
            <div class="reduce-left">
              <div class="reduce-number">
                {{
                  reduceData.saving
                    ? (reduceData.saving / 1000).toFixed(1)
                    : "-- --"
                }}<span>tce</span>
              </div>
              <div class="reduce-name">当年节能量</div>
            </div>
            <div class="reduce-right">
              节能率
              <span>{{
                reduceData.rate ? reduceData.rate * 100 + "%" : "-- --"
              }}</span>
            </div>
          </div>
        </div>
        <div class="heat-box month">
          <div class="heat-title">
            <span>能源成本</span>
            <!-- <div
              v-for="item in multipleArr"
              :key="item.energyTypeId"
              class="multiple-title-item"
              :class="{ active: multipleIndex == item.energyTypeId }"
              @click="changeMultipleIndex(item.energyTypeId)"
            >
              {{ item.energyTypeName }}
            </div> -->
          </div>
          <div class="heat-body month-body">
            <div class="month-title">
              <div class="month-title-number yl-f-column">
                <div class="yl-f-column-num">628</div>
                <div class="yl-f-column-name">总计用能成本(万元)</div>
              </div>
              <div class="month-title-2 yl-f-column">
                <div class="yl-f-column-num down">-13%</div>
                <div class="yl-f-column-name">同比</div>
              </div>
              <div class="month-title-3 yl-f-column">
                <div class="yl-f-column-num up">+0.9%</div>
                <div class="yl-f-column-name">环比</div>
              </div>
            </div>
            <div
              style="width: 100%; height: calc(50% - 28px); margin-bottom: 10px"
            >
              <v-chart ref="chartMonth" :option="optionMonth" />
            </div>
            <!-- <div class="cdm-title">
              <div class="cdm-title-number yl-f-column">
                <div class="yl-f-column-num">74</div>
                <div class="yl-f-column-name">今日节能量成本(万元)</div>
              </div>
              <div class="cdm-title-2 yl-f-column">
                <div class="yl-f-column-num">1343</div>
                <div class="yl-f-column-name">年累计节能量(万元)</div>
              </div>
            </div> -->
            <div style="width: 100%; height: calc(50% - 28px)">
              <v-chart ref="chartCdm" :option="optionCdm" />
            </div>
          </div>
        </div>
        <!-- <div class="heat-box cdm">
          <div class="heat-title">节能减排</div>
          <div class="heat-body cdm-body">
            <div class="cdm-title">
              <div class="cdm-title-number yl-f-column">
                <div class="yl-f-column-num">74</div>
                <div class="yl-f-column-name">今日节能量成本(万元)</div>
              </div>
              <div class="cdm-title-2 yl-f-column">
                <div class="yl-f-column-num">1343</div>
                <div class="yl-f-column-name">年累计节能量(万元)</div>
              </div>
            </div>
            <div style="width: 100%; height: calc(100% - 48px)">
              <v-chart ref="chartCdm" :option="optionCdm" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="floor">
      <div class="heat-box used" v-loading="status.loadUserd">
        <div class="heat-title">能耗指标使用情况</div>
        <div class="heat-body used-body">
          <div class="used-item">
            <div class="item-header">用电量(KWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used0.webp"
              alt=""
            />
            <div class="item-num">8526.3</div>
          </div>
          <div class="used-item">
            <div class="item-header">用水量(t)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used1.webp"
              alt=""
            />
            <div class="item-num">
              <!-- {{ usedData.water === null ? "-- --" : usedData.water }} -->
              256.7
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">燃气用量(m³)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used2.webp"
              alt=""
            />
            <div class="item-num">{{ gascon }}</div>
          </div>
          <div class="used-item">
            <div class="item-header">热水量(m³)</div>
            <!-- 第二个页面热水接口 -->
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used3.webp"
              alt=""
            />
            <div class="item-num">{{ heatcon }}</div>
          </div>
          <div class="used-item">
            <div class="item-header">制冷量(RT)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used4.webp"
              alt=""
            />
            <div class="item-num">
              {{ coolcon }}
            </div>
          </div>
          <!-- <div class="used-item">
            <div class="item-header">绿色能源(KWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used5.webp"
              alt=""
            />
            <div class="item-num">{{ usedData.cold===null?'-- --':usedData.water }}</div>
          </div> -->
        </div>
      </div>
      <div class="heat-box multiple" v-loading="status.comprehensiveData">
        <div class="heat-title">
          <span>综合能耗分析</span>
          <div class="multiple-title">
            <div
              v-for="item in multipleArr"
              :key="item.energyTypeId"
              class="multiple-title-item"
              :class="{ active: multipleIndex == item.energyTypeId }"
              @click="changeMultipleIndex(item)"
            >
              {{ item.energyTypeName }}
            </div>
          </div>
        </div>
        <div class="heat-body multiple-body">
          <v-chart ref="chartMultiple" :option="optionMultiple" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import * as dayjs from "dayjs";
import { mapState } from "vuex";

import {
  screenQuery,
  getDayElecUse,
  getDayColdUse,
  getDayAirUse,
  // getDayHeatUse,
  getDayWaterUse,
  getOverallCOP,
  // getShowTypes,
  periodDiff,
  getMonitorRealtime,
  monthData,
  // useEnergySearch,
  yearEnergySaving,
  totalSavingFee,
  totalReduction,
  comprehensiveData,
  comprehensiveArea,
  comprehensivePerson,
  multipleQuery,
  getDiffData,
} from "@/request/apiLg";
export default {
  name: "projectOverview",
  props: {
    bgimg: {
      type: String,
      default: "bg1",
    },
    projectCode: {
      type: String,
      default: "zgc",
    },
  },
  data() {
    const date1 = dayjs();
    const monthNum = date1.diff('"2022-04-01"', "month");
    return {
      monthNum: monthNum,
      gascon: 0,
      heatcon: 0,
      coolcon: 0,
      rqdataArr: {
        zlj: {
          today: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
          yesterday: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
        gdj: {
          today: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
          yesterday: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
        zrj: {
          today: [
            50, 48, 14, 21, 29, 75, 293, 162, 232, 533, 164, 329, 347, 132, 246,
            145, 253, 52, 284, 67, 88, 451, 215,
          ],
          yesterday: [
            59, 58, 24, 29, 49, 85, 299, 166, 230, 538, 165, 321, 327, 139, 266,
            165, 259, 59, 294, 97, 97, 471, 235,
          ],
        },
      },
      yoyData2: dayjs().subtract(1, "year").format("YYYY年MM月"),
      qoqData2: dayjs().subtract(1, "month").format("YYYY年MM月"),
      optionBase: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          formatter: "{b}: {c}万元",
        },
        legend: {},
        grid: {
          top: "15px",
          left: "0",
          right: "40px",
          bottom: "15px",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          name: "万元",
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          // 轴上延申线
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63, 109, 248, 0.5)"],
              width: 1,
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          // 单位设置
          nameTextStyle: {
            color: "#fff",
            verticalAlign: "top",
            align: "left",
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [],
      },
      optionMultiple: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "35px",
          left: "20px",
          right: "50px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          name: "kgce",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            interval: 0,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisTick: {
            show: false,
          },
          // 单位设置
          nameTextStyle: {
            color: "#fff",
            verticalAlign: "top",
            align: "left",
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          // 轴上延申线
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63, 109, 248, 0.5)"],
              width: 1,
              type: "dashed",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [],
      },
      optionCdm: {},
      optionMonth: {},
      multipleArr: [],
      multipleIndex: "0",
      status: {
        censusStatus: true,
        analysis: true,
        comprehensiveData: true,
        reduceData: true,
        loadUserd: true,
        loadCenterData: true,
      },
      censusData: {},
      censusDataAll: {},
      yoyData: {},
      qoqData: {},
      analysisData: {},
      reduceData: {},
      usedData: {},
      periodDiffQuery: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
      },
      periodDiffQueryAll: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: "2022-01-01 00:00:00",
      },
      coldData: "",
      hotData: "",
      getMonitorRealtimeData: "",
      // 本月的数据
      comprehensiveAreaQuery: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
      },
      monthDataQueryYoy: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(monthNum, "month")
          .subtract(1, "year")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(1, "year")
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs()
          .subtract(monthNum, "month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      monthDataQueryQoq: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(monthNum, "month")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs()
          .subtract(monthNum, "month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      useEnergyQuery: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
        energyTypeId: "",
        interval: 1,
        projectId: "bjzgc",
        startTime: dayjs()
          .subtract(monthNum, "month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "HOURS",
      },
      useEnergyQueryAll: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs()
          .subtract(monthNum, "month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "HOURS",
        pointId: "",
        tomorrowEndTime: dayjs()
          .subtract(monthNum, "month")
          .format("YYYY-MM-DD 00:00:00"),
        tomorrowStartTime: dayjs()
          .subtract(monthNum, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
      },
      yearTotalQuery: {
        endTime: dayjs()
          .subtract(monthNum, "month")
          .format("YYYY-MM-DD HH:mm:ss"),
        startTime: dayjs()
          .subtract(monthNum, "month")
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00"),
      },
      totalSavingFeeData: "",
      totalReductionData: "",
      // 综合能耗统计点
      "energy.consume.yoy.point": "",
      "energy.use.hot.water": "",
      "hot.run.mode": "",
      "energy.electric.point.p": "",
    };
  },
  components: {},
  methods: {
    loadEcharts() {
      // 当月能源成本
      this.loadChartCdm();
      this.loadChartMonth();
    },
    loadChartCdm() {
      this.optionCdm = {
        ...this.optionBase,
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          formatter: function (params) {
            let res = "";
            if (params[0].value !== 0) {
              res += `预计${params[0].name}：${params[0].value}万元<br/>`;
            } else if (params[1].value !== 0) {
              res += `${params[1].name}：${params[1].value}万元<br/>`;
            }
            return res;
          },
        },
        xAxis: [
          {
            type: "value",
            name: "万元",
            // 轴上的刻度设置
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#fff",
              },
            },
            // 轴线样式设置
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.5)"],
                width: 1,
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
          },
          {
            type: "value",
            name: "万元",
            // 轴上的刻度设置
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#fff",
              },
            },
            // 轴线样式设置
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.5)"],
                width: 1,
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
          },
        ],
        yAxis: {
          type: "category",
          data: ["节省水费", "节省燃气", "节省电费"],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 8,
            stack: "Ad",
            data: [28, 0, 49], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(150, 150, 150, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " rgba(150, 150, 150, 0.55)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(150, 150, 150, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " rgba(150, 150, 150, 0.55)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
          {
            type: "bar",
            barWidth: 8,
            stack: "Ad",
            data: [0, 35, 0], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(63, 109, 248, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " #55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(63, 109, 248, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " #55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
        ],
      };
      this.$refs.chartCdm.setOption(this.optionCdm);
    },
    loadChartMonth() {
      this.optionMonth = {
        ...this.optionBase,
        series: [
          {
            type: "bar",
            barWidth: 8,
            data: [160, 199, 270], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(210, 172, 73, 0.35)" }, //柱图渐变色
                  { offset: 1, color: "rgba(210, 172, 73, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(210, 172, 73, 0.8)" }, //柱图渐变色
                  { offset: 1, color: "rgba(210, 172, 73, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
        ],
      };
      this.optionMonth.yAxis.data = ["用水成本", "用气成本", "用电成本"];
      this.$refs.chartMonth.setOption(this.optionMonth);
    },
    loadMultiple(firstData, lastData) {
      let num = new Date().getHours() + 1;
      let optionMultiple = {
        ...this.optionMultiple,
        series: [
          {
            barWidth: 8,
            name: "今天",
            type: "bar",
            data: firstData.map((item, index) => {
              if (index < num) {
                return item;
              } else {
                return null;
              }
            }),
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#47A5E1" }, //柱图渐变色
                  { offset: 1, color: "#55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55FFBC" }, //柱图渐变色
                  { offset: 1, color: "#55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "昨天",
            type: "bar",
            barWidth: 8,
            data: lastData,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#3F6DF8" }, //柱图渐变色
                  { offset: 1, color: "#4AB4F8" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#4AB4F8" }, //柱图渐变色
                  { offset: 1, color: "#4AB4F8" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
        ],
      };
      if (this.multipleIndex === this.multipleArr[1].energyTypeId) {
        optionMultiple.xAxis.name = "KWh";
      } else if (this.multipleIndex === this.multipleArr[2].energyTypeId) {
        optionMultiple.xAxis.name = "m³";
      } else {
        optionMultiple.xAxis.name = "tce";
      }
      console.log("this.multipleIndex", this.multipleIndex);
      this.$refs.chartMultiple.setOption(optionMultiple);
      this.status.comprehensiveData = false;
    },
    periodDiff() {
      // 综合能耗统计
      periodDiff({
        ...this.periodDiffQuery,
        pointId: this["energy.consume.yoy.point"],
      }).then((res) => {
        if (res.data.code == 0) {
          this.censusData.value = res.data.data.formatValue;
          this.status.censusStatus = false;
        }
      });
    },
    screenQuery() {
      // 节能率
      screenQuery("energy.saving.rate").then((res) => {
        if (res.data.code == 0) {
          this.reduceData.rate = res.data.data.value;
        }
      });
      // 采暖系统-制冷
      screenQuery("cold.run.mode").then((res) => {
        if (res.data.code == 0) {
          this.coldData = res.data.value;
        }
      });
    },
    multipleQuery() {
      return new Promise((resolve, reject) => {
        multipleQuery([
          "energy.saving.rate",
          "cold.run.mode",
          "total.energy.consume.point.id",
          "energy.gas.point",
          "energy.electric.point",
          "energy.electric.point.p",
          "energy.consume.yoy.point",
          "energy.use.hot.water",
          "hot.run.mode",
        ])
          .then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data;
              this.reduceData.rate = data["energy.saving.rate"].value;
              this.coldData = data["cold.run.mode"];
              this.multipleArr = [
                {
                  energyTypeId: data["total.energy.consume.point.id"].value,
                  energyTypeName: "综合",
                },
                {
                  energyTypeId: data["energy.electric.point"].value,
                  energyTypeName: "电",
                },
                {
                  energyTypeId: data["energy.gas.point"].value,
                  energyTypeName: "燃气",
                },
              ];
              this.multipleIndex = data["total.energy.consume.point.id"].value;
              // 综合能耗的点
              this["energy.consume.yoy.point"] =
                data["energy.consume.yoy.point"].value;
              // 能耗使用情况-热水量
              this["energy.use.hot.water"] = data["energy.use.hot.water"].value;
              this["hot.run.mode"] = data["hot.run.mode"].value;
              this["energy.electric.point.p"] =
                data["energy.electric.point.p"].value;

              this.useEnergySearch();
              resolve(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadAnalysis() {
      // 单位能耗分析
      // 单位面积总和能耗 综合能耗*1000/面积
      // screenQuery("total.area").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       debugger;
      //       this.analysisData.area = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.area = "-- --";
      //     }
      //   }
      // });
      // // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("total.people").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
      // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("electric.charge").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
      // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("coefficient").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
    },
    // 加载能耗指标使用情况
    async loadUserd() {
      // 用电
      await getDayElecUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.electric = res.data.data.formatValue;
        }
      });
      // 用冷量
      await getDayColdUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.cold = res.data.data.formatValue;
        }
      });
      // 燃气
      await getDayAirUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.gas = res.data.data.formatValue;
        }
      });
      // 用热
      await getDiffData([this["energy.use.hot.water"]]).then((res) => {
        if (res.data.code === 0) {
          this.usedData.heat = res.data.data[this["energy.use.hot.water"]];
        }
      });
      // 用水
      await getDayWaterUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.water = res.data.data.formatValue;
        }
      });

      await getOverallCOP().then((res) => {
        if (res.data.code === 0) {
          this.usedData.cop = res.data.data.formatValue;
        }
      });

      this.status.loadUserd = false;
    },
    // getShowTypes() {
    //   getShowTypes().then((res) => {
    //     if (res.data.code === 0) {
    //       this.multipleArr = [
    //         { energyTypeId: -1, energyTypeName: "综合" },
    //         { energyTypeId: "elec", energyTypeName: "电" },
    //         { energyTypeId: "gas", energyTypeName: "燃气" }
    //         // ...res.data.data,
    //       ];
    //       this.multipleIndex = -1;
    //       this.useEnergySearch();
    //     }
    //   });
    // },
    changeMultipleIndex(item) {
      this.status.comprehensiveData = true;
      this.multipleIndex = item.energyTypeId;
      console.log("aaaaaaaa", item);
      if (item.energyTypeName == "燃气") {
        this.status.comprehensiveData = false;
        let month = new Date().getMonth() + 1;
        if ([6, 7, 8, 9].indexOf(month)) {
          this.coolcon = 8907.1;
          this.heatcon = 0;
          this.gascon = this.sum(this.rqdataArr.zlj.today);
          this.loadMultiple(
            this.rqdataArr.zlj.today,
            this.rqdataArr.zlj.yesterday
          );
        } else if ([1, 2, 3, 12].indexOf(month)) {
          this.coolcon = 0;
          this.heatcon = 4605.2;
          this.gascon = this.sum(this.rqdataArr.zrj.today);
          this.loadMultiple(
            this.rqdataArr.zrj.today,
            this.rqdataArr.zrj.yesterday
          );
        } else {
          this.coolcon = 0;
          this.heatcon = 0;
          this.gascon = 0;
          this.loadMultiple(
            this.rqdataArr.gdj.today,
            this.rqdataArr.gdj.yesterday
          );
        }
      } else {
        this.useEnergySearch();
      }
    },
    sum(arr) {
      let num = 0;
      arr.forEach((item) => {
        num += item;
      });
      return num;
    },
    // 综合能耗分析
    useEnergySearch() {
      this.useEnergyQueryAll.pointId = this.multipleIndex;
      comprehensiveData(this.useEnergyQueryAll).then((res) => {
        if (res.data.code === 0) {
          let firstData = res.data.data.first.map((res) => {
            return res.formatValue || "0";
          });
          let lastData = res.data.data.last.map((res) => {
            return res.formatValue;
          });
          this.loadMultiple(firstData, lastData);
        }
      });
    },
    monthData() {
      this.monthDataQueryYoy.pointId = this["energy.consume.yoy.point"];
      monthData(this.monthDataQueryYoy).then((res) => {
        if (res.data.code === 0) {
          this.yoyData = res.data.data;
          this.yoyData.time = dayjs()
            .subtract(this.monthNum, "month")
            .subtract(1, "year")
            .format("YYYY年MM月");
        }
      });
      this.monthDataQueryQoq.pointId = this["energy.consume.yoy.point"];
      monthData(this.monthDataQueryQoq).then((res) => {
        if (res.data.code === 0) {
          this.qoqData = res.data.data;
          this.qoqData.time = dayjs().subtract(5, "month").format("YYYY年MM月");
        }
      });
    },
    async loadCenterData() {
      // 实时负荷
      await this.getMonitorRealtime();
      // 根据时间获取节能
      await this.yearEnergySaving();
      // 获取累计节费
      await this.totalSavingFee();
      // 获取累计减排
      await this.totalReduction();
      this.status.loadCenterData = false;
    },
    getMonitorRealtime() {
      // 实时负荷
      getMonitorRealtime([
        this["energy.electric.point.p"],
        this["hot.run.mode"],
      ]).then((res) => {
        if (res.data.code === 0) {
          this.getMonitorRealtimeData =
            res.data.data[this["energy.electric.point.p"]];
          this.hotData = res.data.data[this["hot.run.mode"]];
        }
      });
    },
    showFull() {
      if (this.isProjectOverview == "true") {
        this.$store.commit("mutationsHideProject");
      } else {
        this.$store.commit("mutationsShowProject");
      }
    },
    yearEnergySaving() {
      yearEnergySaving(this.yearTotalQuery).then((res) => {
        if (res.data.code == 0) {
          this.reduceData.saving = res.data.data.formatValue;
          this.status.reduceData = false;
        }
      });
      yearEnergySaving({
        ...this.yearTotalQuery,
        startTime: "2022-01-01 00:00:00",
      }).then((res) => {
        if (res.data.code == 0) {
          this.censusDataAll.value = res.data.data.formatValue;
        }
      });
    },
    totalSavingFee() {
      totalSavingFee(this.yearTotalQuery).then((res) => {
        if (res.data.code === 0) {
          this.totalSavingFeeData = res.data.data.formatValue;
        }
      });
    },
    totalReduction() {
      totalReduction(this.yearTotalQuery).then((res) => {
        if (res.data.code === 0) {
          this.totalReductionData = res.data.data.formatValue;
        }
      });
    },
    // 单位面积综合能耗
    comprehensiveArea() {
      comprehensiveArea(this.comprehensiveAreaQuery).then((res) => {
        if (res.data.code === 0) {
          this.analysisData.area = res.data.data ? res.data.data.toFixed(1) : 0;
        }
      });
    },
    // 单位面积综合能耗
    comprehensivePerson() {
      comprehensivePerson(this.comprehensiveAreaQuery).then((res) => {
        if (res.data.code === 0) {
          this.analysisData.people = res.data.data
            ? res.data.data.toFixed(1)
            : 0;
          this.status.analysis = false;
        }
      });
    },
    goColdSystem() {
      if (this.currentProjectId === "jkjd") {
        this.$router.push("/actual/cold-system-jk");
        // if (window != window.parent) {
        //   window.parent.childChangeRouter(
        //     "http://platform.hubenergy.cn:8089/#/actual/heating-system-jk"
        //   );
        // }
      } else {
        this.$router.push({
          name: "heatingSystem",
        });
      }
    },
    goHeatingSystem() {
      if (this.currentProjectId === "jkjd") {
        this.$router.push("/actual/heating-system-jk");
        // if (window != window.parent) {
        //   window.parent.childChangeRouter(
        //     "http://platform.hubenergy.cn:8089/#/actual/heating-system-jk"
        //   );
        // }
      } else {
        this.$router.push({
          name: "heatingSystem",
        });
      }
    },
  },
  computed: {
    ...mapState({
      isProjectOverview: (state) => state.isProjectOverview,
      currentProjectId: (state) => state.currentProjectId,
    }),
  },
  watch: {
    // currentProjectId() {
    //   this.getShowTypes();
    // },
  },
  mounted() {
    // 根据Key获取的数据字典的数据
    this.multipleQuery().then((res) => {
      if (res) {
        // 赋值点
        // this.getMultipleKey()
        // 加载Echarts图表
        this.loadEcharts();
        // 综合能耗统计
        this.periodDiff();
        // 同比环比
        this.monthData();
        // 能耗指标使用情况
        this.loadUserd();
        // 综合能耗分析的类型
        // 加载中心部分数据
        this.loadCenterData();
        this.comprehensiveArea();
        this.comprehensivePerson();
      }
    });
    let month = new Date().getMonth() + 1;
    if ([6, 7, 8, 9].indexOf(month)) {
      this.coolcon = 8907.1;
      this.heatcon = 0;
      this.gascon = this.sum(this.rqdataArr.zlj.today);
    } else if ([1, 2, 3, 12].indexOf(month)) {
      this.coolcon = 0;
      this.heatcon = 4605.2;
      this.gascon = this.sum(this.rqdataArr.zrj.today);
    } else {
      this.coolcon = 0;
      this.heatcon = 0;
      this.gascon = 0;
    }
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer !important;
}
::-webkit-scrollbar-track {
  border-bottom: transparent;
  border-radius: 10px;
  background: transparent;
  cursor: pointer !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: block !important;
  cursor: pointer !important;
}
.projectOverview {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #fff;
  &.bg1 {
    background: url("~@/assets/heatingSystem/bgimg1.png") no-repeat center;
    background-size: 100% 100%;
  }
  &.bg2 {
    background: url("~@/assets/heatingSystem/bgimg2.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  /deep/ .el-loading-mask {
    background-color: #1f386566;
  }
  .header {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .h-left {
      width: 23%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .heat-box {
        height: 32%;
        width: 100%;
      }
    }
    .h-center {
      .center-header {
        width: 100%;
        margin-top: 5px;
      }
      .center-list {
        display: flex;
        justify-content: center;
        .center-item {
          width: 153px;
          margin: 0 16px;
          .center-val {
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            color: #ffffff;
            line-height: 45px;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              line-height: 22px;
              margin-left: 5px;
            }
          }
          .center-name {
            height: 33px;
            text-align: center;
            line-height: 33px;
            background: url("~@/assets/heatingSystem/center-item.png") no-repeat
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
    .h-right {
      width: 23%;
      min-width: 370px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .heat-box {
        background: linear-gradient(
          90deg,
          rgba(4, 11, 32, 0.5) 0%,
          rgba(2, 4, 12, 0.54) 13%,
          rgba(0, 0, 0, 0.9) 100%
        );
      }
    }
  }

  // 综合能耗统计
  .census {
    min-height: 180px;
    background: linear-gradient(
      90deg,
      rgba(4, 11, 32, 0) 0%,
      rgba(2, 4, 12, 0.3) 13%,
      rgba(0, 0, 0, 0.5) 100%
    );
    .census-body {
      height: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
      width: 100%;
      .census-left {
        flex: 0 0 152px;
        margin-right: 4%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .census-left-number {
          text-align: center;
          position: relative;
          bottom: -10px;
          .c-l-n-value {
            font-size: 36px;
            font-weight: bold;
            color: #ffffff;
            line-height: 42px;
            text-shadow: 0px 0px 0px #236aff;
          }
          .c-l-n-name {
            font-size: 14px;
            font-weight: 400;
            color: #d8d8d8;
          }
        }
        .census-left-name {
          width: 152px;
          height: 68px;
          background: url("~@/assets/heatingSystemWebp/总能耗.webp") no-repeat
            center;
          background-size: 100% 100%;
          position: relative;
          text-align: center;
          line-height: 60px;
          font-size: 14px;
          .census-left-name-img {
            width: 76px;
            height: 11px;
            background: url("~@/assets/heatingSystem/census-number-img.png")
              no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            bottom: 7px;
            left: 40px;
          }
        }
      }
      .census-variety {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .census-variety-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 63px;
          background: linear-gradient(
            90deg,
            #3fb2ff3d 0%,
            rgba(11, 53, 182, 0) 100%
          );
          border-top: 2px solid;
          border-image: linear-gradient(90deg, #4568ad, rgba(236, 6, 37, 0)) 10
            0;
          padding-left: 8%;
          &:first-child {
            margin-bottom: 8px;
          }
          .c-variety-item-number {
            font-size: 24px;
            font-weight: bold;
            i {
              margin-right: -4px;
            }
            &.bottom {
              color: #00dbff;
            }
            &.top {
              color: #fbbb18;
            }
          }
          .c-variety-item-time {
            font-size: 14px;
            font-weight: 400;
            color: #d8d8d8;
          }
        }
      }
    }
  }
  // 单位能耗分析
  .analysis {
    min-height: 170px;
    background: linear-gradient(
      90deg,
      rgba(4, 11, 32, 0) 0%,
      rgba(2, 4, 12, 0.48) 13%,
      rgba(0, 0, 0, 0.8) 100%
    );
    .analysis-body {
      overflow-x: scroll;
      height: calc(100% - 32px);
      padding: 0 10px;
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .analysis-item {
        flex: 1;
        height: 85%;
        border: 1px solid #3b76ff81;
        position: relative;
        &:before,
        &:after {
          content: " ";
          display: block;
          width: 7px;
          height: 100%;
          border-top: 2px solid #1dfff5;
          border-bottom: 2px solid #1dfff5;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:after {
          right: 0;
          left: auto;
        }
        &:first-child {
          margin-right: 10px;
        }
        .analysis-title {
          height: 32px;
          background: #1945524d;
          line-height: 32px;
          padding-left: 12px;
        }
        .analysis-item-center {
          height: calc(100% - 32px);
          display: flex;
          align-items: center;
          padding-left: 7px;

          .analysis-left {
            width: 50px;
            height: 50px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.square {
              background: url("~@/assets/heatingSystemWebp/analysis-left-square.webp")
                no-repeat center;
              background-size: 100% 100%;
            }
            &.user {
              background: url("~@/assets/heatingSystemWebp/analysis-left-user.webp")
                no-repeat center;
              background-size: 100% 100%;
            }
          }
          .analysis-right {
            .analysis-right-num {
              font-size: 26px;
              font-weight: bold;
              color: #00dbff;
              text-shadow: 0px 0px 0px #236aff;
            }
            .analysis-right-val {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  // 智能模式
  .pattern.heat-box {
    height: 35% !important;
    min-height: 260px;
    background: linear-gradient(
      90deg,
      rgba(4, 11, 32, 0) 0%,
      rgba(2, 4, 12, 0.42) 13%,
      rgba(0, 0, 0, 0.7) 100%
    );
    .pattern-body {
      height: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0;
      .pattern-item {
        width: 380px;
        height: calc(50% - 8px);
        max-height: 114px;
        position: relative;
        display: flex;
        align-items: center;
        .pattern-left {
          width: 7px;
          height: 100%;
          position: absolute;
        }
        &.stop {
          background: linear-gradient(
            90deg,
            rgb(0, 55, 78, 0.7) 0%,
            rgb(0, 55, 78, 0.6) 10%,
            rgb(0, 55, 78, 0.5) 20%,
            rgba(0, 55, 78, 0.3) 40%,
            rgba(11, 53, 182, 0) 80%
          );
          background-size: 100% 100%;
          .pattern-left {
            background: url("~@/assets/heatingSystem/pattern-left-stop.png")
              no-repeat center;
            background-size: 100% 100%;
          }
          .pattern-icon {
            // background: url("~@/assets/heatingSystemWebp/引导按钮.webp")
            //   no-repeat center;
            // background-size: 100% 100%;
          }
          .pattern-val span {
            color: #00dbff;
          }
          .pattern-right {
            height: 90%;
            display: flex;
            align-items: center;
            position: relative;
            img {
              height: 100%;
              z-index: 2;
            }
            .pattern-right-bg {
              background: linear-gradient(90deg, #1664bd, transparent);
              width: 190px;
              height: 88%;
              position: absolute;
              left: 50%;
              border-top: 2px solid;
              border-bottom: 2px solid;
              border-image: linear-gradient(90deg, #00d0f4, rgba(236, 6, 37, 0))
                10 0;
            }
          }
        }
        &.start {
          cursor: pointer;
          background: linear-gradient(
            90deg,
            rgb(124, 108, 0, 0.7) 0%,
            rgb(124, 108, 0, 0.6) 10%,
            rgb(124, 108, 0, 0.5) 20%,
            rgba(124, 108, 0, 0.3) 40%,
            rgba(11, 53, 182, 0) 80%
          );
          background-size: 100% 100%;
          .pattern-left {
            background: url("~@/assets/heatingSystem/pattern-left-start.png")
              no-repeat center;
            background-size: 100% 100%;
          }
          // .pattern-icon {
          //   background: url("~@/assets/heatingSystemWebp/引导按钮2.webp")
          //     no-repeat center;
          //   background-size: 100% 100%;
          // }
          .pattern-val span {
            color: #fbbb18;
          }
          .pattern-right {
            height: 90%;
            display: flex;
            align-items: center;
            position: relative;
            img {
              height: 100%;
              z-index: 2;
            }
            .pattern-right-bg {
              background: linear-gradient(90deg, #b67f09, transparent);
              width: 190px;
              height: 88%;
              position: absolute;
              left: 50%;
              border-top: 2px solid;
              border-bottom: 2px solid;
              border-image: linear-gradient(90deg, #e4c717, rgba(236, 6, 37, 0))
                10 0;
            }
          }
        }
        .icon {
          width: 34px;
          height: 34px;
        }
        .pattern-name {
          width: 36px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          line-height: 23px;
          margin-left: 7%;
        }
        .pattern-icon {
          width: 34px;
          height: 34px;
          // margin-left: 110px;
        }
        .pattern-val {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
  .reduce {
    height: 25%;
    min-height: 140px;
    .reduce-body {
      height: calc(100% - 32px);
      display: flex;
      justify-content: center;
      align-items: center;
      .reduce-left {
        margin-right: 30px;
        .reduce-number {
          font-size: 34px;
          text-shadow: 0px 0px 0px #236aff;
          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
        .reduce-name {
          font-size: 16px;
          color: #d8d8d8;
        }
      }
      .reduce-right {
        width: 163px;
        height: 67px;
        background: url("~@/assets/heatingSystem/reduce-bg.png") no-repeat
          center;
        background-size: 100% 100%;
        font-size: 16px;
        color: #d8d8d8;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 26px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .month {
    height: 80%;
    // min-height: 290px;
    .month-body {
      padding: 17px 15px 0 15px;
      .month-title {
        display: flex;
        justify-content: space-between;
        .yl-f-column-num {
          font-size: 22px;
          font-weight: bold;
          &.down {
            color: #00dbff;
          }
          &.up {
            color: #fbbb18;
          }
        }
      }
    }
    .cdm-title {
      display: flex;
      > div {
        margin-right: 20px;
      }
      .yl-f-column-num {
        font-size: 22px;
        font-weight: bold;
        &.down {
          color: #00dbff;
        }
        &.up {
          color: #fbbb18;
        }
      }
    }
  }
  .cdm {
    height: 40%;
    .cdm-body {
      padding: 17px 15px 0 15px;
    }
  }
  .floor {
    height: 25%;
    width: 100%;
    display: flex;
    .used {
      height: 100%;
      width: 40%;
    }
    .multiple {
      height: 100%;
      flex: 1;
    }
  }
  .used {
    background: #021230e6;
    .heat-title {
      // width: 100%;
    }
    .used-body {
      padding: 0 1%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .used-item {
        flex: 1;
        background: url("~@/assets/heatingSystem/used-item.png") no-repeat
          center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2%;
        margin: 0 5px;
        .item-header {
          width: 100%;
          height: 32px;
          line-height: 32px;
          padding-left: 10%;
          background: linear-gradient(
            180deg,
            #2c50bf66 0%,
            rgba(11, 53, 182, 0) 100%
          );
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 6%;
        }
        .item-icon {
          width: 54%;
          margin-bottom: 4%;
          min-height: 71px;
        }
        .item-num {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          min-height: 27px;
        }
      }
    }
  }
  .multiple {
    background: linear-gradient(179deg, #020e33 0%, #000000 100%);
    opacity: 0.9;
    .heat-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      .multiple-title {
        display: flex;
        .multiple-title-item {
          height: 32px;
          padding: 0 10px;
          line-height: 32px;
          text-align: center;
          border-radius: 0px;
          border: 1px solid #3f6df8;
          font-size: 14px;
          cursor: pointer;
          &.active {
            background-color: #3f6df8;
          }
        }
      }
    }
  }
  .bgWebp {
    position: absolute;
  }
  .heat-body {
    height: calc(100% - 32px);
  }
  .yl-f-column {
    display: flex;
    flex-direction: column;
    .yl-f-column-num {
      font-size: 26px;
    }
    .yl-f-column-name {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
.heat-title {
  width: 100%b;
  height: 32px;
  line-height: 30px;
  padding-left: 35px;
  background: url("~@/assets/heatingSystem/标题底纹理.png") no-repeat left,
    linear-gradient(90deg, #2c50bf 0%, #2c50bf 0%, rgba(11, 53, 182, 0) 100%);
  background-size: auto 100%;
  opacity: 0.9;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #8ba7fa, rgba(236, 6, 37, 0) 50%) 10 0;
}
.showFull {
  background: #136bba33;
  float: right;
  border: 1px solid #136bba;
  padding: 0 5px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
